import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Environment, ENVIRONMENT, JwtSigner } from '@netfoundry-ui/shared/model';
import { AnimationOptions } from 'ngx-lottie';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { FeatureService } from '@netfoundry-ui/shared/services';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { JwtSignerFormComponent } from '@netfoundry-ui/feature/form/jwt-signer-form';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { JwtSignerService } from '@netfoundry-ui/shared/apiv2';
import { TableFilterService } from '@netfoundry-ui/feature/shared-services';

@Component({
    selector: 'app-jwt-signers',
    templateUrl: './jwt-signers.component.html',
    styleUrls: ['./jwt-signers.component.scss'],
})
export class JwtSignersComponent implements OnInit, OnDestroy {
    displayedItems: any;
    columnDefs: any;
    changedPage: any;
    showNoData: any;
    canCreate = false;
    subscription = new Subscription();

    dialogRef: MatDialogRef<JwtSignerFormComponent> | undefined;
    confirmRef: MatDialogRef<ConfirmComponent> | undefined;

    private data: JwtSigner[] = [];

    showInfoBubble = false;
    lottieOptions: AnimationOptions = {
        path: '/assets/animations/SmartZiggy.json',
    };
    typeName = 'JWT Signer';
    tableId = 'external-jwt-policies';
    infoBubbleTitle = 'What is an External JWT Signer?';
    infoBubbleText =
        'External JWT Signers allow external identity providers to facilitate authentication with a Ziti network.' +
        ' External JWT Signers are used in conjunction with' +
        ' Authentication Policies to allow identities to authenticate using a JWT.';
    infoBubbleUrl = 'https://docs.openziti.io/docs/learn/core-concepts/security/authentication/external-jwt-signers/';

    constructor(
        public dialogForm: MatDialog,
        private growlerService: GrowlerService,
        public svc: JwtSignerService,
        @Inject(ENVIRONMENT) private environment: Environment,
        private featureService: FeatureService,
        public filterService: TableFilterService
    ) {}

    ngOnInit(): void {
        this.svc.init();
        this.canCreate = this.svc.canCreate();

        this.subscription.add(
            this.svc.filteredRecords.pipe().subscribe((result) => {
                this.displayedItems = result || [];
                this.showNoData = this.displayedItems.length === 0 && _.isEmpty(this.svc.columnFilters?.name);
            })
        );

        this.subscription.add(
            this.svc.confirmDelete.subscribe((item: JwtSigner) => {
                this.openConfirm('delete', item);
            })
        );

        this.initTableColumns();

        this.subscription.add(
            // subscribing to the filter service to handle the change in page
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.svc.page = pageNum;
                this.svc.pageHasChanged.next(true);
                this.svc.debouncedRefreshRecords();
            })
        );

        this.subscription.add(
            // subscribing to the filter service to handle search filter changes
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.svc.filterString = filterString;
                this.svc.filterHasChanged.next(true);
                this.svc.debouncedRefreshRecords();
            })
        );

        this.showInfoBubble = this.featureService.getShowInfoBubble(this.tableId) ?? true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    actionRequested(request: any) {
        switch (request.action) {
            case 'toggleAll':
                this.svc.toggleAll(this.displayedItems);
                break;
            case 'toggleItem':
                this.svc.toggle(request.item);
                break;
            case 'delete':
                this.svc.confirmAction(request.action, request.item);
                break;
            case 'update':
                this.edit(true, request.item);
                break;
            default:
                break;
        }
    }

    edit(addEvent: boolean, signer?: JwtSigner) {
        if (addEvent) {
            if ((signer?.id && this.svc.canUpdate(signer.id)) || (!signer && this.svc.canCreate())) {
                if (this.svc.networkReady()) {
                    const model = signer ?? new JwtSigner();
                    model.networkId = this.svc.currentNetwork.getValue().id;
                    this.dialogRef = this.dialogForm.open(JwtSignerFormComponent, {
                        data: { model },
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    });

                    this.dialogRef.afterOpened().subscribe(() => {
                        this.dialogRef?.componentInstance.requestUpdate.subscribe((signer: JwtSigner) => {
                            this.svc.save(signer).then((errors) => {
                                if (errors.length === 0) {
                                    this.dialogRef?.close();
                                    this.showEditSuccess();
                                } else {
                                    this.dialogRef?.componentInstance.errorUpdate.next(errors);
                                }
                            });
                        });
                        this.dialogRef?.componentInstance.requestValidate.subscribe((signer: JwtSigner) => {
                            this.svc.validate(signer).then((errors: any[]) => {
                                this.dialogRef?.componentInstance.errorUpdate.next(errors);
                            });
                        });
                    });
                } else {
                    this.showNetworkNotReadyError();
                }
            }
        }
    }

    hideInfoBubble() {
        this.featureService.setShowInfoBubble(this.tableId, false);
        this.showInfoBubble = false;
    }

    openConfirm(action: string, item?: JwtSigner) {
        if (action === 'delete') {
            const deletingInfo = this.getDeletingPrompt(item);

            const data = {
                title: 'Delete',
                appendId: 'Router',
                subtitle: deletingInfo.deleteString,
                bulletList: deletingInfo.names,
                icon: 'DescructiveDelete',
                isDestructive: this.svc.enableDeleteProtection,
                action: 'Yes',
                itemName: deletingInfo.names[0].name,
            };
            this.confirmRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '340px',
                width: '600px',
                autoFocus: false,
            });
            this.confirmRef.afterClosed().subscribe((result) => {
                if (result === undefined || result['loggingOut'] === undefined) {
                    this.svc.delete(result).catch((err) => {
                        this.showDeleteFailure(err);
                    });
                }
            });
        }
    }

    private showDeleteFailure(msg: string) {
        this.growlerService.show(new GrowlerData('error', 'Error', 'One or More JWT Signers failed to delete.', msg));
    }

    private showEditSuccess() {
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'JWT Signer Save Complete', 'The information has been saved')
        );
    }

    getDeletingPrompt(item?: JwtSigner) {
        const names = [];
        if (item) names.push(item);
        else {
            for (const item of this.displayedItems) {
                if ((item as any).selected) {
                    names.push(item);
                }
            }
        }

        const deleteString = 'Are you sure you would like to delete ';

        return {
            deleteString: deleteString,
            names: names,
        };
    }

    private showEditError() {
        this.growlerService.show(
            new GrowlerData('error', 'Error', 'Unable to Save', 'Unable to Save the provided JWT Signer')
        );
    }

    private showNetworkNotReadyError() {
        this.growlerService.show(
            new GrowlerData('error', 'Error', 'Network not ready', 'The network is not yet PROVISIONED')
        );
    }

    initTableColumns() {
        const headerComponentParams = {
            filterType: 'TEXTINPUT',
            columnFilters: this.svc.columnFilters,
        };

        this.columnDefs = [
            {
                colId: 'name',
                width: 120,
                minWidth: 100,
                field: 'name',
                tooltipField: 'name',
                sortColumn: this.svc.sort.bind(this.svc),
                sortable: true,
                headerName: 'Name',
                headerComponent: TableHeaderDefaultComponent,
                headerComponentParams,
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'issuer',
                width: 100,
                minWidth: 100,
                sortable: true,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'issuer',
                tooltipField: 'Issuer',
                headerName: 'Issuer',
                headerComponent: TableHeaderDefaultComponent,
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
            {
                colId: 'claimsProperty',
                width: 100,
                minWidth: 100,
                sortable: true,
                field: 'claimsProperty',
                tooltipField: 'claimsProperty',
                headerName: 'Match JWT to Identity',

                headerComponentParams,
                cellRenderer: (params: any) =>
                    `Claim ${params.data.claimsProperty} to ${params.data.useExternalId ? 'External Id' : 'Id'}`,
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: false,
            },
            {
                colId: 'status',
                width: 80,
                minWidth: 60,
                sortable: false,
                sortColumn: this.svc.sort.bind(this.svc),
                field: 'status',
                tooltipField: 'status',
                headerName: 'Status',
                headerComponentParams,
                onCellClicked: (params: any) => {
                    this.edit(true, params.data);
                },
                resizable: true,
                cellClass: 'nf-cell-vert-align tCol',
                filter: true,
            },
        ];
    }
}
