<div class="fullModal opened" id="AuthPolicyAddModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            {{ isEditing ? 'Modify Your' : 'Create A New' }} JWT Signer
        </div>
        <div class="subtitle">{{ isEditing ? 'Modify' : 'Enter' }} the JWT Signer Details</div>
        <div
            class="subhelp"
            matTooltip="The JWT Signer is the party that 'creates' a JWT token and signs it with their private key."
            matTooltipPosition="below"
        >
            What's a JWT Signer?
        </div>
        <div class="sized">
            <div class="label row mt16" style="width: 82%">
                Signer Name
                <span [ngClass]="{ error: errMap.name }" class="note">{{ errMap.name ? 'Invalid' : 'Required' }}</span>
            </div>
            <div class="row">
                <input
                    style="width: 82%"
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errMap.name }"
                    class="restrictNormal"
                    id="editName"
                    placeholder="OKTA JWT"
                    type="text"
                />
                <div
                    class="darkBox sideToggle"
                    style="float: right; width: 15%; padding: 5px 12px; vertical-align: middle"
                >
                    <div (click)="model.enabled = !model.enabled" class="toggle" [class.on]="model.enabled">
                        <span [hidden]="!model.enabled" style="margin-right: 1rem">YES</span>
                        <span [hidden]="model.enabled" style="margin-left: 1rem">NO</span>
                        <div class="switch"></div>
                    </div>

                    <div
                        class="label"
                        style="
                            color: var(--navigation);
                            display: inline-block;
                            margin: 0;
                            width: 100%;
                            text-align: center;
                            vertical-align: middle;
                            font-size: smaller;
                        "
                    >
                        Enabled
                    </div>
                </div>
                <span [hidden]="!errMap.name" class="err nameSpan">{{ errMap.name }}</span>
            </div>
            <div class="label row">
                Issuer
                <div
                    class="infoicon"
                    matTooltip="The issuer defined within the JWT token."
                    matTooltipPosition="below"
                ></div>
                <span [ngClass]="{ error: errMap.issuer }" class="note">{{
                    errMap.issuer ? 'Invalid' : 'Required'
                }}</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.issuer"
                    [ngClass]="{ error: errMap.issuer }"
                    class="restrictNormal"
                    id="editIssuer"
                    placeholder="https://my-tenant.okta.com"
                    type="text"
                />
                <span [hidden]="!errMap.issuer" class="err">{{ errMap.issuer }}</span>
            </div>
            <div class="label row">
                Audience
                <div
                    class="infoicon"
                    matTooltip="The audience as define within the JWT token."
                    matTooltipPosition="below"
                ></div>
                <span [ngClass]="{ error: errMap.audience }" class="note">{{
                    errMap.audience ? 'Invalid' : 'Required'
                }}</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.audience"
                    [ngClass]="{ error: errMap.audience }"
                    class="restrictNormal"
                    id="editAudience"
                    placeholder="7LsrzfEZS0Tm0hgnoaijU7qrdK5mpo7c"
                    type="text"
                />
                <span [hidden]="!errMap.audience" class="err">{{ errMap.audience }}</span>
            </div>
            <div class="label row">
                JWKS Endpoint
                <div
                    class="infoicon"
                    matTooltip="a JSON Web Key Set (JWKS) endpoint that returns a public key that can be used to validate the token signature."
                    matTooltipPosition="below"
                ></div>
                <span [ngClass]="{ error: errMap.jwksEndpoint }" class="note">{{
                    errMap.jwksEndpoint ? 'Invalid' : 'Required'
                }}</span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.jwksEndpoint"
                    [ngClass]="{ error: errMap.jwksEndpoint }"
                    class="restrictNormal"
                    id="editJwksEndpoint"
                    placeholder="https://my-tenant.okta.com/jwks"
                    type="text"
                />
                <span [hidden]="!errMap.jwksEndpoint" class="err">{{ errMap.jwksEndpoint }}</span>
            </div>

            <div class="label row">
                Match JWT to Identity When:
                <div
                    class="infoicon"
                    matTooltip="Map the JWT claim in the token that should be used to map against the unique externalId or id property on Identities."
                    matTooltipPosition="below"
                ></div>
                <span [ngClass]="{ error: errMap.claimsProperty }" class="note">{{
                    errMap.claimsProperty ? 'Invalid' : 'Required'
                }}</span>
            </div>
            <div class="darkBox">
                <div
                    class="label"
                    style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                >
                    JWT Claim's
                    <div class="infoicon" matTooltip="default 'Subject'" matTooltipPosition="below"></div>
                </div>
                <div class="row">
                    <input
                        (keyup.enter)="validate()"
                        [(ngModel)]="model.claimsProperty"
                        [ngClass]="{ error: errMap.claimsProperty }"
                        class="restrictNormal"
                        id="editJwtClaims"
                        maxlength="64"
                        placeholder="Subject"
                        type="text"
                    />
                    <span [hidden]="!errMap.claimsProperty" class="err">{{ errMap.claimsProperty }}</span>
                </div>
                <div
                    class="label"
                    style="width: 100%; text-align: center; display: inline-block; margin-top: 4px; margin-bottom: 4px"
                >
                    Equals
                </div>
                <div
                    class="label"
                    style="color: var(--navigation); display: inline-block; margin-top: 0; vertical-align: middle"
                >
                    Identity's
                    <div class="infoicon" matTooltip="default 'id'" matTooltipPosition="below"></div>
                </div>
                <div class="row">
                    <mat-radio-group
                        [(ngModel)]="model.useExternalId"
                        aria-label="Select an option"
                        class="nf-hosted-radio"
                        style="line-height: 45px"
                    >
                        <mat-radio-button [value]="false">Id</mat-radio-button>
                        <span style="padding: 20px; color: var(--icon); font-weight: 600">OR</span>
                        <mat-radio-button [value]="true">External Id</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="label row">
                External Auth URL
                <div class="infoicon" [matTooltip]="" matTooltipPosition="below"></div>
                <span [ngClass]="{ error: errMap.externalAuthUrl }" class="note">
                    {{ errMap.externalAuthUrl ? 'Invalid' : model.useExternalId ? 'Optional' : '' }}
                </span>
            </div>
            <div class="row">
                <input
                    (keyup.enter)="validate()"
                    [(ngModel)]="model.externalAuthUrl"
                    [ngClass]="{ error: errMap.externalAuthUrl }"
                    class="restrictNormal mb0"
                    id="editExtAuthUrl"
                    placeholder="https://my-ziti.com"
                    type="text"
                />
                <span [hidden]="!errMap.externalAuthUrl" class="err">{{ errMap.externalAuthUrl }}</span>
            </div>
            <div class="row buttonRow right">
                <button (click)="save()" *ngIf="!processing" class="save">
                    {{ isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
        <div class="spacer"></div>
        <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
</div>
