import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthClientConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper';
import { FeatureActionButtonModule } from '@netfoundry-ui/feature/action-button';
import { FeatureAutoFabricModule } from '@netfoundry-ui/feature/auto-fabric';
import { FeatureCreditCardWarningModule } from '@netfoundry-ui/feature/credit-card-warning';
import { FeaturePageTourModule } from '@netfoundry-ui/feature/page-tour';
import { FeatureSideFilterModule } from '@netfoundry-ui/feature/side-filter';
import { FeatureViewsTemplateModule } from '@netfoundry-ui/feature/views/template';
import { FeatureAppSwitcherModule } from '@netfoundry-ui/feature/app-switcher';
import {
    NETWORK_SERVICE,
    NetworkServiceV2,
    SharedApiv2Module,
    TEMPLATE_SEARCH_SERVICE,
    V7TemplateSearchService,
    ZitiControllerService,
    V7BrowzerServiceSearchService,
    BROWZER_SERVICE_SEARCH_SERVICE
} from '@netfoundry-ui/shared/apiv2';

import {
    Auth0Service,
    AUTHENTICATION,
    IAM_CONFIGURATION,
    IamConfig,
    LOGIN_SERVICE,
    LoginService,
    SharedAuthorizationModule,
} from '@netfoundry-ui/shared/authorization';
import { SharedBannerMessageModule } from '@netfoundry-ui/shared/banner-message';
import { SharedClientSyncsModule } from '@netfoundry-ui/shared/client-syncs';
import { SharedElasticsearchModule } from '@netfoundry-ui/shared/elasticsearch';
import { SharedGrowlerModule } from '@netfoundry-ui/shared/growler';
import {
    _VERSION,
    ADVANCED_SERVICE_DIALOG,
    APPWAN_DIALOG,
    Attribute,
    CONFIG_SERVICE_DIALOG,
    EDGE_ROUTER_UPGRADE_DIALOG,
    ENDPOINT_DIALOG,
    EndpointV2,
    ENROLLMENT_DIALOG,
    ENVIRONMENT,
    EVENT_FIELDS,
    NETWORK_RESTART_DIALOG,
    NETWORK_UPGRADE_DIALOG,
    NetworkV2,
    ROUTER_DIALOG,
    SERVICE_DIALOG,
} from '@netfoundry-ui/shared/model';
import { SharedNetflowModule } from '@netfoundry-ui/shared/netflow';
import { SharedNotificationModule } from '@netfoundry-ui/shared/notification';
import { SharedRecentAlertsModule } from '@netfoundry-ui/shared/recent-alerts';
import {
  HTTP_CLIENT,
  SharedServicesModule
} from '@netfoundry-ui/shared/services';
// SubFeature
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgArrayPipesModule } from 'ngx-pipes';
import { NgxStripeModule } from 'ngx-stripe';
import { TimeagoModule } from 'ngx-timeago';
// NF Feature
// Main Feature
import { NgxHateoasClientConfigurationService, NgxHateoasClientModule } from '@lagoshny/ngx-hateoas-client';

import {
    FeatureFormZitiEndpointFormModule,
    ZitiEndpointFormComponent,
} from '@netfoundry-ui/feature/form/ziti-endpoint-form';
import {
    FeatureFormPlatformServiceFormModule,
    PlatformServiceFormComponent,
} from '@netfoundry-ui/feature/form/platform-service-form';
import {
    FeatureFormZitiEdgeRouterFormModule,
    ZitiEdgeRouterComponent,
} from '@netfoundry-ui/feature/form/ziti-edge-router-form';
import { AdvancedPlatformServiceFormComponent } from '@netfoundry-ui/feature/form/advanced-platform-service-form';
import { AdvancedServiceConfigFormComponent } from '@netfoundry-ui/feature/form/advanced-service-config-form';
import { ZitiAppwanComponent } from '@netfoundry-ui/feature/form/ziti-appwan';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { EnrollmentInstructionsComponent, FeatureGettingStartedModule } from '@netfoundry-ui/feature/getting-started';
import {
    EdgeRouterUpgradeModalComponent,
    FeatureNetworkUpgradeModule,
    NetworkRestartModalComponent,
    NetworkUpgradeModalComponent,
} from '@netfoundry-ui/feature/network-upgrade';
import { FeatureNavigatorModule } from '@netfoundry-ui/feature/navigator';
import {
    MultipleResourceRoutes,
    ResourceRoute,
} from '@lagoshny/ngx-hateoas-client/lib/config/hateoas-configuration.interface';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import {
    ExtensionsNoopService,
    NoopTabInterceptorService,
    SETTINGS_SERVICE,
    SettingsService,
    SHAREDZ_EXTENSION,
    ZAC_WRAPPER_SERVICE,
    ZacWrapperService,
    ZITI_DATA_SERVICE,
    ZITI_DOMAIN_CONTROLLER,
    ZITI_TAB_OVERRIDES,
    ZitiControllerDataService,
} from 'ziti-console-lib';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MOP_EVENT_FIELDS } from "../models/mop-event-fields";

const authConfig: any = {
    clientId: environment.authconfig.clientID,
    domain: environment.authconfig.domain,
    audience: environment.authconfig.audience,
    errorPath: environment.identityConfig.loginReturnUrl,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    redirect_uri: environment.authconfig.callbackURL,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    httpInterceptor: {
        allowedList: [
            {
                uriMatcher: (uri) => {
                    if (uri.indexOf('/tenants/label') >= 0) return false;
                    if (uri.indexOf('/tenants/authorize') >= 0) return false;
                    if (uri.indexOf('/tenants/forgotten-site') >= 0) return false;
                    if (uri.indexOf('/identity/v1/identity-provider-types') >= 0) return false;
                    if (uri.indexOf('/rest/v1/countries') >= 0) return false;
                    if (uri.indexOf('/identity/v1/signup') >= 0) return false;
                    if (uri.indexOf('/identity/v1/tenants/label/') >= 0) return false;
                    if (uri.indexOf('/invitation?') >= 0) return false;
                    if (uri.indexOf('/customer/v1/countries') >= 0) return false;
                    if (uri.indexOf('/customer/v1/email-verification') >= 0) return false;
                    if (uri.indexOf('/customer/v1/signup') >= 0) return false;
                    if (uri.indexOf('/customer/v1/otps') >= 0) return false;
                    if (uri.indexOf('nf-console-branding') >= 0) return false;
                    if (uri.indexOf('/edge/management/v1') >= 0) return false;
                    if (uri.indexOf('/identity/v1/invitations/') >= 0 && uri.indexOf('/revoke') < 0) return false;
                    if (uri.indexOf('sample.tools.netfoundry.io') >= 0) return false;
                    else return true;
                },
            },
        ],
    },
};

function configInitializer(config: AuthClientConfig, iamConfig: IamConfig, http: HttpClient, handler: HttpBackend) {
    const tenantLabel = localStorage.getItem('tenantLabel');
    if (!tenantLabel) {
        const currentUri = window.location?.pathname + window.location?.search;
        if (currentUri.indexOf('/invitation') >= 0 || currentUri.indexOf('/trial') >= 0) {
            authConfig.errorPath = currentUri;
        }
        return () => Promise.resolve(config.set(authConfig));
    } else {
        return () => {
            // Create a new HttpBackend handler so the AuthHttpInterceptor doesn't intercept the init request
            http = new HttpClient(handler);
            const isVanityUrl = localStorage.getItem('isVanityUrl') === 'true';
            let redirectUri = environment.authconfig.callbackURL;
            if (isVanityUrl) {
                let newUri = '';
                if (redirectUri.indexOf('https://') >= 0) {
                    const redirectLocation = redirectUri.slice(8);
                    newUri = `https://${tenantLabel}.${redirectLocation}`;
                } else {
                    const redirectLocation = redirectUri.slice(7);
                    newUri = `http://${tenantLabel}.${redirectLocation}`;
                }
                redirectUri = newUri;
            }
            const fullpath = `${iamConfig.url}tenants/authorize-initiate`;
            const body = { tenantLabel: tenantLabel, intermediateReturnUrl: '' };
            let headers = new HttpHeaders();
            headers = headers.set('Content-Type', 'application/json; charset=utf-8');
            return http
                .post(fullpath, JSON.stringify(body), { headers: headers })
                .toPromise()
                .then((result: any) => {
                    authConfig.nfToken = result.nfToken;
                    authConfig.redirect_uri = redirectUri;
                    return config.set(authConfig);
                })
                .catch((result) => {
                    localStorage.removeItem('tenantLabel');
                    window.location.href = environment.identityConfig.loginReturnUrl;
                });
        };
    }
}

export const elasticConfig = { url: environment.apiUrl, usePublicApi: true };

export const identityConfig = {
    url: environment.identityConfig.url,
    invitationUrl: environment.identityConfig.invitationUrl,
    intermediateReturnUrl: environment.identityConfig.intermediateReturnUrl,
    loginReturnUrl: environment.identityConfig.loginReturnUrl,
};
export const authorizationConfig = {
    url: environment.authorizationUrl,
};

export const netflowConfig = {
    url: environment.netflowUrl,
    pageSize: environment.pageSize,
};
export const notificationConfig = {
    url: environment.notificationUrl,
};

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
    accountUrl = 'netfoundry.zendesk.com';
    override lazyLoad = true;

    callback(zE: any): any {
        zE('webWidget', 'setLocale', 'en');
        zE('webWidget:on', 'close', () => {
            zE('webWidget', 'hide');
        });
    }
}

export const clientSyncsConfig = {
    url: environment.clientSyncsApiUlr,
};

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [AppComponent],
    providers: [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: _VERSION, useValue: VERSION },
        { provide: LOGIN_SERVICE, useClass: LoginService },
        { provide: HTTP_CLIENT, useClass: HttpClient },
        { provide: IAM_CONFIGURATION, useValue: identityConfig },
        { provide: AUTHENTICATION, useExisting: Auth0Service },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: { fullLibraryLoader: () => import('highlight.js'), themePath: 'assets/styles/monokai.css' },
        },
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            deps: [AuthClientConfig, IAM_CONFIGURATION, HttpClient, HttpBackend],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: NETWORK_SERVICE, useClass: NetworkServiceV2 },

        { provide: NETWORK_RESTART_DIALOG, useValue: NetworkRestartModalComponent },
        { provide: NETWORK_UPGRADE_DIALOG, useValue: NetworkUpgradeModalComponent },
        { provide: EDGE_ROUTER_UPGRADE_DIALOG, useValue: EdgeRouterUpgradeModalComponent },
        { provide: ENROLLMENT_DIALOG, useValue: EnrollmentInstructionsComponent },
        { provide: ENDPOINT_DIALOG, useValue: ZitiEndpointFormComponent },
        { provide: SERVICE_DIALOG, useValue: PlatformServiceFormComponent },
        { provide: APPWAN_DIALOG, useValue: ZitiAppwanComponent },
        { provide: ADVANCED_SERVICE_DIALOG, useValue: AdvancedPlatformServiceFormComponent },
        { provide: CONFIG_SERVICE_DIALOG, useValue: AdvancedServiceConfigFormComponent },
        { provide: ROUTER_DIALOG, useValue: ZitiEdgeRouterComponent },
        { provide: ZITI_DOMAIN_CONTROLLER, useClass: ZitiControllerService },
        { provide: ZAC_WRAPPER_SERVICE, useClass: ZacWrapperService },
        { provide: ZITI_TAB_OVERRIDES, useClass: NoopTabInterceptorService },
        { provide: SHAREDZ_EXTENSION, useClass: ExtensionsNoopService },
        { provide: ZITI_DATA_SERVICE, useClass: ZitiControllerDataService },
        { provide: SETTINGS_SERVICE, useClass: SettingsService },
        { provide: EVENT_FIELDS, useValue: MOP_EVENT_FIELDS },
        { provide: TEMPLATE_SEARCH_SERVICE, useClass: V7TemplateSearchService },
        { provide: BROWZER_SERVICE_SEARCH_SERVICE, useClass: V7BrowzerServiceSearchService }
    ],
    imports: [
        FeatureAppSwitcherModule,
        FeatureViewsTemplateModule,
        ChargebeeJsAngularWrapperModule,
        FeatureAutoFabricModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FeatureActionButtonModule,
        UiLoaderModule,
        FeatureCreditCardWarningModule,
        FeaturePageTourModule,
        SharedRecentAlertsModule,
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
        NgArrayPipesModule,
        NgxHateoasClientModule.forRoot(),
        NgxHateoasClientModule.forRoot(),
        NgxPaginationModule,
        NgxStripeModule.forRoot(environment.billingConfig.stripe),
        SharedApiv2Module.forRoot(),
        SharedAuthorizationModule.forRoot(authorizationConfig),
        SharedBannerMessageModule,
        SharedClientSyncsModule.forRoot(clientSyncsConfig),
        SharedElasticsearchModule.forRoot(elasticConfig),
        SharedGrowlerModule,
        SharedNetflowModule.forRoot(netflowConfig),
        SharedNotificationModule.forRoot(notificationConfig),
        TimeagoModule.forRoot(),
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
        RouterModule,
        UiPipesModule,
        FeatureSideFilterModule,
        SharedServicesModule,
        AuthModule.forRoot(),
        FeatureFormZitiEndpointFormModule,
        FeatureFormZitiEdgeRouterFormModule,
        FeatureFormPlatformServiceFormModule,
        FeatureGettingStartedModule,
        LottieModule.forRoot({ player: playerFactory }),
        FeatureNetworkUpgradeModule,
        FeatureGettingStartedModule,
        FeatureNavigatorModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(hateoasConfig: NgxHateoasClientConfigurationService) {
        const v2ResourceRoute: ResourceRoute = {
            rootUrl: environment.v2apiUrl,
        };
        const multipleRoutes: MultipleResourceRoutes = {
            defaultRoute: v2ResourceRoute,
            networkResourceRoute: v2ResourceRoute,
        };
        hateoasConfig.configure({
            http: multipleRoutes,
            logs: { verboseLogs: false },
            useTypes: {
                resources: [NetworkV2, Attribute, EndpointV2],
            },
            halFormat: {
                collections: {
                    embeddedOptional: true,
                },
            },
            cache: { enabled: true, lifeTime: 200 },
            isProduction: environment.production,
            pagination: {
                defaultPage: {
                    page: 0,
                    size: environment.pageSize ?? 50,
                },
            },
        });
    }
}
