<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="CertificateAuthorityAddModal">
    <div class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            {{ isEditing ? 'Modify Your' : 'Create A New' }} Certificate Authority
        </div>
        <div class="subtitle">{{ isEditing ? 'Modify' : 'Enter' }} your CA details</div>
        <div class="sized">
            <div class="label row">
                Certificate Authority Name
                <span [ngClass]="{ error: errorName }" class="note">{{ errorName ? 'Invalid' : 'Required' }}</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="model.name"
                    [ngClass]="{ error: errorName }"
                    class="restrictNormal"
                    id="EditName"
                    maxlength="64"
                    placeholder="Name this CA"
                    type="text"
                />
            </div>

            <div *ngIf="!isEditing" class="label row">
                Certificate Authority Pem
                <span [ngClass]="{ error: errorPem }" class="note">{{ errorPem ? 'Invalid' : 'Required' }}</span>
            </div>
            <div *ngIf="!isEditing" class="row">
                <textarea
                    [(ngModel)]="model.certPem"
                    [ngClass]="{ error: errorPem }"
                    class="restrictNormal"
                    id="EditPem"
                    placeholder="CA Pem"
                    type="text"
                ></textarea>
            </div>
            <div class="grid three">
                <div (click)="toggleAutoEnrollment()" class="checkbox" id="CAAutoEnrollment">
                    <div
                        [ngClass]="{ selected: model.isAutoCaEnrollmentEnabled }"
                        class="tSelect"
                        id="toggleAutoEnrollment"
                    ></div>
                    <div class="label checkBoxLabel">Auto CA Enrollment</div>
                </div>

                <div (click)="toggleOttEnrollment()" class="checkbox" id="OTTCaEnrollment">
                    <div
                        [ngClass]="{ selected: model.isOttCaEnrollmentEnabled }"
                        class="tSelect"
                        id="toggleOttCaEnrollment"
                    ></div>
                    <div class="label checkBoxLabel">OTT Enrollment Enabled</div>
                </div>

                <div (click)="toggleAuthEnabled()" class="checkbox authEnabled" id="AuthEnabled">
                    <div [ngClass]="{ selected: model.isAuthEnabled }" class="tSelect" id="toggleAuthEnabled"></div>
                    <div class="label checkBoxLabel">Auth Enabled</div>
                </div>
            </div>

            <div class="row buttonRow right" style="margin-top: 80px">
                <button (click)="save()" *ngIf="!processing" class="save" id="SaveClientButton">
                    {{ isEditing ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
        </div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="CloseClientButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
