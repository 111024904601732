import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { AccountService, BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { EndpointServiceV2, NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import {
    AuthorizationService,
    AuthService,
    IamService,
    IdletimeoutService,
    LOGIN_SERVICE,
    LoginService,
} from '@netfoundry-ui/shared/authorization';
import { ClientSyncsService } from '@netfoundry-ui/shared/client-syncs';
import { GrowlerComponent, GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    _VERSION,
    AzureSubscription,
    ENVIRONMENT,
    Environment,
    Network,
    NetworkGroup,
    NetworkV2,
    Tenant,
} from '@netfoundry-ui/shared/model';
import { NetflowService } from '@netfoundry-ui/shared/netflow';
import {
    ApiService,
    AzureSubscriptionService,
    BannerService,
    BrandingModeService,
    ClientService,
    CustomerService,
    ErrorHistoryService,
    FeatureService,
    IdentityPreferencesService,
    LoggerService,
    MenuService,
    NetworkGroupService,
    NetworkService,
    OrganizationPreferencesService,
    RefresherService,
    SessionTimerService,
    TokenService,
    URLS,
    v6tov7URLMapping,
    v7tov6URLMapping,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { ShareComponent } from '@netfoundry-ui/shared/share';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import LogRocket from 'logrocket';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, Subscription, take } from 'rxjs';
import _, { get } from 'lodash';
import moment from 'moment';
import { SurveyComponent } from '@netfoundry-ui/feature/survey';
import { WhatsnewService } from '@netfoundry-ui/feature/whatsnew';
import { DOCUMENT } from '@angular/common';

declare const gtag;

const EnjoyHint = get(window, 'EnjoyHint', (p?: any) => {});

// interval for the refresher to be called
const loginCheckTimeout = 30000;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    /**
     * Template Elements V2
     *
     * I am using this area to organize items I know are required for the new design.
     *
     * We should start cleaning up old and unused stuff maybe Rev 2 post templat change
     */
    tabs = '';
    page = '';
    isFilterable = true;
    isLoggedIn = false;
    showLoader = false;
    isDarkMode = false;
    isNavOpen = false;
    showLegacyNav = false;
    isHeader = true;
    isTimeSearchAvailable = false;
    isNotDialLogs = true;
    closeTimeout;
    @ViewChild(GrowlerComponent, { static: true }) growler: GrowlerComponent;
    @ViewChild(ShareComponent, { static: true }) share: ShareComponent;
    location: Location;
    title = 'NetFoundry Console';
    subtitle = 'Console';
    network = '';
    breadcrumb = '';
    bannerMessage = '';
    bannerClass = '';
    showBanner = false;
    showSupportLink = false;
    bannerButtonText = 'Contact';
    resourceType;
    actionTitle = '';
    showTabs = false;
    networks = [];
    url = '';
    isMenuOpen = false;
    isGrowlersOpen = false;
    isNewSelfService = false;
    isSelfService = false;
    isSelfServiceTeams = false;
    showQuickStart = false;
    showUserTabs = false;
    showProfileTabs = false;
    tenantLabel = '';
    brandUrl = '';
    domain = '';
    brandingIsSet = false;
    isShaded = false;
    noPadding = false;
    networkLimits;
    currentAccount;
    currentNetwork: Network | NetworkV2 = new Network({});
    currentOrg: NetworkGroup = new NetworkGroup({});
    previousNetwork: Network | NetworkV2 = new Network({});
    organizationList = [];
    orgLookup = {};
    hasAzureSubscription = false;
    isFirstLoad = true;
    isFirstAdd = true;
    isFirstPage = false;
    dialogRef;
    confirmDialog;
    idleTimeoutWarningActive = false;
    networksV7 = [];
    tenantId;
    hideSideBar = false;
    shortBreadCrumbs = false;
    networkStopped;
    isEnterprise = false;
    showBannerSpinner = false;
    public chargebee: any;
    private growlerServiceSub: Subscription;
    private currentNetworkSub = new Subscription();
    private azureSubSub = new Subscription();
    private networkServiceSub = new Subscription();
    private subscription = new Subscription();
    private selfLinkSub: Subscription = new Subscription();
    private lastErrorSub = new Subscription();
    private paymentProfileSub = new Subscription();
    private subscriptionDetailsSub = new Subscription();
    private hasNoNetworks = false;
    /* Networking Controls From Header */
    private networkServiceGetSub: Subscription = new Subscription();
    // variable to hold the refresher
    private loginCheckRefresher;
    private wasWizardShown = false;
    private gettingStartedWasShown = false;
    private billingInvalid = false;
    private wasPricingObtained = false;
    private werePermissionsObtained = false;
    private wasAzureSubscriptionInitialized = false;
    private currentSubscription: any;
    private currentPaymentProfile: any;
    private currentContract: any;
    private disableV6 = false;
    private landingPageInit = false;
    private getPaymentProfileAfterAccount = false;

    public appLoading = false;

    constructor(
        private logger: LoggerService,
        private router: Router,
        private refresher: RefresherService,
        @Inject(LOGIN_SERVICE) public login: LoginService,
        private growlService: GrowlerService,
        private accountService: AccountService,
        public apiService: ApiService,
        private networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private changeDetector: ChangeDetectorRef,
        private iamService: IamService,
        public dialogForm: MatDialog,
        private idleTimeout: IdletimeoutService,
        public brandingService: BrandingModeService,
        public featureService: FeatureService,
        public billingEnabledService: BillingEnabledService,
        private titleService: Title,
        private azureSubscriptionService: AzureSubscriptionService,
        private zenDeskService: NgxZendeskWebwidgetService,
        private billingService: BillingService,
        private cookieService: CookieService,
        private zitiEnabledService: ZitiEnabledService,
        public authorizationService: AuthorizationService,
        private menuService: MenuService,
        private organizationService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        private errorHistoryService: ErrorHistoryService,
        private authService: AuthService,
        private clientSyncService: ClientSyncsService,
        private netflowService: NetflowService,
        private tokenService: TokenService,
        private clientService: ClientService,
        private sessionTimerService: SessionTimerService,
        private endpointServiceV2: EndpointServiceV2,
        private identityPreferencesService: IdentityPreferencesService,
        private organizationPreferencesService: OrganizationPreferencesService,
        private customerService: CustomerService,
        private whatsNewService: WhatsnewService,
        private bannerService: BannerService,
        @Inject(ENVIRONMENT) public environment: Environment,
        @Inject(_VERSION) private version: any,
        @Inject(DOCUMENT) private document
    ) {
        this.initLogRocket();
    }

    private initLogRocket(): void {
        if (!this.environment.disableLogRocket) {
            LogRocket.init(`pnjasd/netfoundry-${this.environment.domain}`, {
                release: `${this.version.version} ${this.version.buildDate} ${this.version.buildTime}`,
                shouldParseXHRBlob: true,
                browser: {
                    urlSanitizer: (url) => {
                        let sanitizedUrl = url;
                        sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**redacted**');
                        sanitizedUrl = sanitizedUrl.replace(/identityId=([^&]*)/, 'identityId=**redacted**');
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sanitizedUrl = sanitizedUrl.replace(/\/client\/(.*)/i, '/client/**redacted**');
                        return sanitizedUrl;
                    },
                },
                network: {
                    requestSanitizer: (request) => {
                        if (request.headers['Authorization']) {
                            request.headers['Authorization'] = '**redacted**';
                        }
                        return request;
                    },

                    responseSanitizer: (response) => {
                        if (response.body) {
                            if (response.body['nfToken']) {
                                response.body = '**redacted**';
                            }
                        }
                        return response;
                    },
                },
            });
            LogRocket.getSessionURL(function (sessionURL) {
                gtag('send', {
                    hitType: 'event',
                    eventCategory: 'LogRocket',
                    eventAction: sessionURL,
                });
            });
        }
    }

    ngAfterViewInit() {
        // load hubspot script for surveys
        const head = this.document.getElementsByTagName('head')[0];
        const s = this.document.createElement('script');
        s.id = 'hs-script-loader';
        s.type = 'text/javascript';
        s.src = `//js.hs-scripts.com/${this.environment.hsId}.js`;
        head.appendChild(s);
    }

    get isShadeBackground() {
        return this.url === '/attributes-explorer';
    }

    get hideLegacySearch() {
        return (
            this.page === URLS.BROWZER ||
            this.page === URLS.ZITIENDPOINTS ||
            this.page === URLS.ZITISERVICES ||
            this.page === URLS.ZITIROUTERS ||
            this.page === URLS.ZITI_SERVICE_ER_POLICIES ||
            this.page === URLS.ZITIAPPS ||
            this.page === URLS.USER ||
            this.page === URLS.USERS ||
            this.page === URLS.TOKENS ||
            this.page === URLS.ORGANIZATIONS ||
            this.page === URLS.USER_ROLES ||
            this.page === URLS.ZITICONFIGS ||
            this.page === URLS.ZITICONFIGTYPES ||
            this.page === URLS.NETWORK_EVENTS ||
            this.page === URLS.OLD_NETWORK ||
            this.page === URLS.NETWORK ||
            this.page === URLS.NETWORKS_COMBINED ||
            this.page === URLS.NETWORK_CONTROLLERS ||
            this.page === URLS.ALLOCATED_IPS ||
            this.page === URLS.ZITIPOLICIES ||
            this.page === URLS.POSTURE_CHECKS ||
            this.page === URLS.AUTH_POLICIES ||
            this.page === URLS.JWT_SIGNERS ||
            this.page === URLS.MANAGEMENT_EVENTS ||
            this.page === URLS.STORED_SECRETS ||
            this.page === URLS.BROWZER_BOOTSTRAPPERS
        );
    }

    sleep(timeout) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('Sleeping for ' + timeout + '...');
            }, timeout);
        });
    }

    // function for keeping the user from timing out
    @HostListener('click', [])
    @HostListener('document:keydown', [])
    @HostListener('window:scroll', [])
    @HostListener('document:mousemove', [])
    onUserMovement() {
        if (this.isLoggedIn && !this.idleTimeoutWarningActive) {
            // if the user is logged in and the user clicks their mouse, scrolls the page, clicks a key, or moves their mouse
            //  reset the idle timeout
            this.idleTimeout.initIdleTimeout();
        }
    }

    async ngOnInit() {
        await this.zenDeskService.initZendesk();
        this.zenDeskService.zE('webWidget', 'hide');

        if (this.environment.domain !== 'localhost') {
            const script = document.createElement('script');
            script.src = 'assets/scripts/google-analytics.js';
            document.body.appendChild(script);
        }
        this.disableV6 = this.environment.disableV6;
        // this.chargebee = window['Chargebee'];
        this.chargebee = window['Chargebee'].init({
            site: this.environment.chargebeeConfig.site,
            publishableKey: this.environment.chargebeeConfig.publishableKey,
        });

        this.subscription.add(
            this.apiService.currentUserPreferences.subscribe((userPreferences) => {
                const tenantLabel = _.isEmpty(this.tenantLabel)
                    ? localStorage.getItem('tenantLabel')
                    : this.tenantLabel;
                this.isNewSelfService =
                    this.isNewSelfService || localStorage.getItem('nf_new_self_service_' + tenantLabel) === 'true';
                const isNewInvitedUser = localStorage.getItem('newInvitedUser_' + this.tenantLabel) === 'true';
                if (this.isNewSelfService || isNewInvitedUser) {
                  this.featureService.enableNewNavigation = true;
                }
                this.handleWhatsNewDisplay();
                const page = this.url.split(';')[0].split('?')[0];
                this.setupSubtitle(page);
            })
        );

        this.subscription.add(
            this.tokenService.logout.subscribe(() => {
                this.growlService.show(
                    new GrowlerData(
                        'error',
                        'Error',
                        'Session Expired',
                        'Your session has expired, please login again to continue'
                    )
                );
                this.login.logout(false, true);
            })
        );
        this.subscription.add(
            this.apiService.appLoading.subscribe((isLoading) => {
                this.appLoading = isLoading;
            })
        );
        this.zenDeskService.zE('webWidget', 'updateSettings', {
            webWidget: { zIndex: -9999 },
        });
        // this.zenDeskService.zE("webWidget", "hide");
        this.isNavOpen = this.menuService.areaId != null && this.menuService.areaId.length > 0;
        this.subscription.add(
            this.menuService.area.subscribe((area) => {
                this.isNavOpen = area.length > 0;
                this.showLegacyNav = area !== 'Network' && area !== '';
            })
        );
        if (this.environment['faviconUrl'] != null) {
            this.setFavicon(this.environment['faviconUrl']);
        }
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                localStorage.setItem('tenantId', tenant.id);
                this.tenantId = tenant.id;
                this.tenantLabel = tenant.label;
                const html = document.getElementsByTagName('html')[0];

                this.brandingService.fetchBrandCss().then((rawCss) => {
                    html.style.cssText = rawCss;
                    this.brandingIsSet = true;
                });

                if (this.featureService.isNewSelfServiceWorkflow(this.tenantLabel)) {
                    this.showGettingStarted();
                }
                this.isNewSelfService = localStorage.getItem('nf_new_self_service_' + this.tenantLabel) === 'true';
                if (this.isNewSelfService) {
                    localStorage.removeItem('nf_new_self_service_' + this.tenantLabel);
                }
                if (this.tenantId === this.environment.pldtId) {
                    this.idleTimeout.setTimeoutInterval(this.environment.pldtTimeoutSeconds);
                }
            })
        );

        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = org;
            })
        );

        this.subscription.add(
            this.accountService.currentAccount.subscribe((account) => {
                this.currentAccount = account;
                this.isEnterprise = account?.billingMode === 'Enterprise';
                this.isSelfService = account?.billingMode === 'SelfService';
                if (!_.isEmpty(account.id)) {
                    this.billingService
                        .getContractsByAccountId(account.id)
                        .toPromise()
                        .then((contracts: any) => {
                            this.currentContract = _.head(contracts);
                            this.apiService.setCurrentContract(this.currentContract);
                        });
                }
            })
        );

        this.subscription.add(
            this.apiService.currentSubscription.subscribe((subscription) => {
                this.currentSubscription = subscription;
                this.subscriptionChanged(subscription);
                const targetIds = [];
                if (this.currentAccount?.id) {
                    targetIds.push(this.currentAccount.id);
                }
                if (this.currentSubscription?.subscriptionId) {
                    targetIds.push(this.currentSubscription?.subscriptionId);
                }
                if (targetIds.length > 0) {
                    this.checkBannerStatus(targetIds);
                }
            })
        );

        this.subscription.add(
            this.apiService.currentPaymentProfile.subscribe((paymentProfile) => {
                this.currentPaymentProfile = paymentProfile;
                this.paymentProfileChanged(paymentProfile);
            })
        );

        this.subscription.add(
            this.billingEnabledService.isBillingEnabled.subscribe((billingEnabled) => {
                this.setBillingEnabled(billingEnabled);
            })
        );

        this.login.handleVanityUrl();
        this.login.handleAuthenticationWithHash();
        this.growlerServiceSub = this.growlService.data$.subscribe((data) => {
            this.showTheGrowler(data);
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                clearTimeout(this.refresher.refreshTimerId);
            }
            if (event instanceof NavigationEnd) {
                if ((<any>window).gtag) {
                    (<any>window).gtag('set', 'page', event.urlAfterRedirects);
                    (<any>window).gtag('send', 'pageview');
                }
                const page = event.url.split(';')[0].split('?')[0];
                this.page = page;
                const newIsLoggedIn = this.login.isAuthenticated();
                // checking if the user is actually logged in
                if (this.isLoggedIn && !newIsLoggedIn) {
                    // if the user's token is no longer valid, logging them out
                    this.logger.info('Session token is no longer valid, logging out: ', event.url);
                    this.isLoggedIn = false;
                    // clearing the timeout before logging out
                    this.subtitle = 'Login';
                    this.idleTimeout.clearIdleTimeout();
                    this.login.logout(false);
                    this.zenDeskService.zE('webWidget', 'hide');
                    this.werePermissionsObtained = false;
                    this.wasAzureSubscriptionInitialized = false;
                } else if (
                    page === URLS.SELFSERVICE ||
                    page === URLS.SIGNUP ||
                    page === URLS.LOGIN ||
                    page === URLS.OLD_LOGIN
                ) {
                    // the user will be logged out by going to any of these pages and we don't want to redirect them
                    // from their intended destination if they navigate to the singup or self service page.
                    // Marking isLoggedIn as false and preventing the getOrganization call from being made
                    this.isLoggedIn = false;
                    // clearing the timeout before logging out
                    this.idleTimeout.clearIdleTimeout();
                } else {
                    if (this.authorizationService.canListTenants() && this.apiService.theTenantIs.id == null) {
                        this.iamService.get('tenants', localStorage.getItem('tenantId')).subscribe((tenant) => {
                            this.apiService.setCurrentTenant(new Tenant(tenant));
                        });
                    }
                    if (!this.isLoggedIn && newIsLoggedIn) {
                        if (
                            !this.authorizationService.canListNetworks() &&
                            !this.authorizationService.canListOrganizations() &&
                            !this.authorizationService.canListTenants()
                        ) {
                          if (this.authorizationService.canViewBillingSubscriptions() || this.authorizationService.canViewPaymentProfile()) {
                            this.appLoading = true;
                            this.apiService.currentUser.subscribe((user: any) => {
                              this.accountService.getAccountDetailsForTenantId(user?.tenantId).then((account: any) => {
                                this.isEnterprise = account.billingMode === 'Enterprise';
                                this.billingEnabledService.checkBilling().then(() => {
                                  this.appLoading = false;
                                  this.menuService.setArea('Support');
                                  this.openBilling();
                                }).catch(() => {
                                  this.appLoading = false;
                                });
                              }).catch(() => {
                                this.appLoading = false;
                              });
                            });
                          } else {
                            /**
                             * If the user has no permissions, assume that this is because they are managing a session and navigate directly to the
                             * endoint session page
                             *
                             * this is done to get rid of the initial load to the dashboard before loading to the endpoint session page
                             *
                             * TODO develop a sepearete default screen if there are no permissions
                             * navigate there first and then go to the endpoint session page
                             */
                            this.idleTimeout.setTimeoutInterval(this.environment.endpointSessionTimeoutSeconds);
                            if (localStorage.getItem('tenantId') === this.environment.pldtId) {
                              this.idleTimeout.setTimeoutInterval(this.environment.pldtTimeoutSeconds);
                            }
                            // if the user is logged in, kick off the idle timeout service
                            this.idleTimeout.initIdleTimeout();
                            // ensuring that the token renewal is scheduled
                            this.login.scheduleRenewal();
                            this.sessionTimerService.setSessionStart();
                            this.hideSideBar = true;
                            this.shortBreadCrumbs = true;
                          }
                        } else if (
                            this.authorizationService.canListTenants() &&
                            !this.authorizationService.canListNetworks() &&
                            !this.authorizationService.canListOrganizations()
                        ) {
                            // if the user can list tenants, don't navigate to the dashboard since they can't view it
                            this.router.navigate([URLS.USERS]);
                        } else {
                            this.showLoader = true;
                            this.hideSideBar = false;
                            this.shortBreadCrumbs = false;
                            lastValueFrom(this.iamService.get('identities', 'self').pipe(take(1))).then((identity) => {
                                this.apiService.setCurrentUser(identity);
                            });

                            lastValueFrom(this.organizationService.get().pipe(take(1))).then((orgs) => {
                                this.orgLookup = {};
                                this.organizationList = this.sortByPipe.transform(orgs, 'createdAt', 'asc');
                                if (this.authorizationService.canListNetworks()) {
                                    this.loadNetworks();
                                } else if (page === URLS.HOME || page === URLS.OLD_HOME || page === '/') {
                                    if (this.tenantId) {
                                        this.router.navigate([URLS.USERS]);
                                    } else {
                                        this.router.navigate([URLS.LOGS]);
                                    }
                                    this.showLoader = false;
                                } else {
                                    this.showLoader = false;
                                }
                                for (const org of this.organizationList) {
                                    this.orgLookup[org.id] = org as NetworkGroup[];
                                }

                                this.organizationPreferencesService.getPreferences().then((result) => {
                                    this.logger.info('preferences', result);
                                    if (!result && this.tenantId) {
                                        this.organizationPreferencesService
                                            .createOrgPreference(this.tenantId)
                                            .subscribe((result) => result);
                                        this.organizationPreferencesService.getPreferences();
                                    }
                                });
                            });
                            // if the user is logged in, kick off the idle timeout service
                            this.idleTimeout.initIdleTimeout();
                            // ensuring that the token renewal is scheduled
                            this.login.scheduleRenewal();
                        }
                    }
                    this.isLoggedIn = newIsLoggedIn;
                    if (!this.brandingIsSet) {
                        // make sure we have org info before setting the branding
                        const html = document.getElementsByTagName('html')[0];

                        if (!this.tenantLabel) {
                            this.tenantLabel = localStorage.getItem('tenantLabel');
                        }
                        this.brandingService.fetchBrandCss(this.tenantLabel).then((rawCss) => {
                            html.style.cssText = rawCss;
                            this.brandingIsSet = true;
                        });
                    }
                }
                this.url = event.url;
                this.isMenuOpen = false;
                this.showTabs = false;
                this.showUserTabs = false;
                this.showProfileTabs = false;
                this.setupSubtitle(page);
                this.handleQuickStartVisibility(page);
            }
        });
        // setting a check to validate the user is logged in to run every 30 seconds
        this.loginCheckRefresher = setInterval(() => {
            // checking if the user was previously logged in. if they were not, it doesn't make sense to try to log them out
            if (this.isLoggedIn) {
                // checking whether or not the user is logged in
                this.isLoggedIn = this.login.isAuthenticated();
                // if the auth check fails, logging the user out
                if (!this.isLoggedIn) {
                    this.logger.info('Session token is no longer valid, logging out');
                    this.login.logout(false);
                    this.zenDeskService.zE('webWidget', 'hide');
                }
            }
        }, loginCheckTimeout);
        // Watch the current network
        this.currentNetworkSub.add(
            this.apiService.currentNetwork.subscribe((network) => {
                if (_.isEmpty(network?.id)) {
                    this.setTempOrganization();
                    return;
                }
                this.previousNetwork = network;
                if (this.breadcrumb === 'Dashboard') {
                    if (network.name) {
                        this.titleService.setTitle('NetFoundry: ' + network.name);
                    } else {
                        this.titleService.setTitle('NetFoundry Console');
                    }
                }

                // this.currentNetworkName = network.name;
                let organizationId;
                const version = this.apiService.getNetworkVersion(network);
                if (version < 7) {
                    this.currentNetwork = new Network(network);
                    organizationId = this.currentNetwork.getLinkedResourceId('organization');
                } else {
                    this.currentNetwork = network as unknown as NetworkV2;
                    organizationId = this.currentNetwork.networkGroupId;
                }
                // @FIX ME - we should clean this up, but the feature service doesn't work quite right if we remove it -MG
                if (this.currentOrg.id == null || this.currentOrg.id !== organizationId) {
                    this.logger.info('attempting to set current org');
                    if (organizationId != null) {
                        this.setOrganization(organizationId);
                    } else {
                        this.setTempOrganization();
                    }
                    this.initLandingPage();
                }
                if (this.router.url === URLS.HOME || this.router.url === URLS.OLD_HOME) {
                    this.subtitle = this.currentNetwork.name !== undefined ? this.currentNetwork.name : 'Console';
                    this.titleService.setTitle('NetFoundry: ' + this.subtitle);
                }
                this.initNetworkLimits();
                this.handleBannerMessageVisibility();
            })
        );
        this.lastErrorSub.add(
            this.apiService.lastError.subscribe((error) => {
                this.logger.info('TEST: error.headers.get(x-b3-traceid): ', error.headers.get('x-b3-traceid'));
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && (typeof error.error === 'string' || error.error instanceof String)) {
                    const errorObject = JSON.parse(String(error.error));
                    if (errorObject && errorObject[0] && errorObject[0]['message']) {
                        errorMessage = errorObject[0]['message'];
                    } else if (errorObject && errorObject.message) {
                        errorMessage = errorObject.message;
                    }
                } else if (error.error && error.error[0] && error.error[0]['message']) {
                    errorMessage = error.error[0]['message'];
                } else if (error.error && error.error.detail) {
                    errorMessage = error.error.detail;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', errorMessage));
            })
        );
        this.lastErrorSub.add(
            this.iamService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', errorMessage));
            })
        );
        this.lastErrorSub.add(
            this.authService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', error.message));
            })
        );
        this.lastErrorSub.add(
            this.clientSyncService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorMessage = error.message;
                if (error.error && error.error.message) {
                    errorMessage = error.message;
                }
                let errorHistoryMessage = errorMessage;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', error.message));
            })
        );
        this.lastErrorSub.add(
            this.netflowService.lastError.subscribe((error) => {
                const traceId = error.headers.get('x-b3-traceid');
                let errorHistoryMessage = error.message;
                if (traceId != null) {
                    errorHistoryMessage += ' - ' + traceId;
                }
                this.errorHistoryService.addError(errorHistoryMessage);
                this.growlService.show(new GrowlerData('error', 'Error', 'An error occurred', error.message));
            })
        );
        // subscribing to the timeout warning alert. when this subscription is triggered and the alert is true
        //  then the user should be warned that they are about to be logged out due to being idle
        this.subscription.add(
            this.idleTimeout.timeoutWarningAlert.subscribe((timeoutAlert) => {
                if (timeoutAlert === true) {
                    this.idleTimeoutWarningActive = true;
                    let interval;
                    let unit;
                    if (this.environment.defaultTimeoutWarningSeconds < 121) {
                        unit = 'seconds';
                        interval = this.environment.defaultTimeoutWarningSeconds;
                    } else {
                        unit = 'minutes';
                        interval = Math.floor(this.environment.defaultSessionTimeoutSeconds / 60);
                    }
                    const data = {
                        title: 'Your Session is About to Timeout',
                        appendId: 'AppComponent',
                        subtitle: `Are you still there? You have ${interval} ${unit} before being signed off. Would you like to stay signed in?`,
                        icon: 'Timeout',
                        action: 'Yes Please',
                        cancelAction: 'No, sign me out',
                        isTimeoutWarning: true,
                    };
                    this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                        data: data,
                        height: '340px',
                        width: '600px',
                        autoFocus: false,
                    });
                    this.confirmDialog.beforeClosed().subscribe((result) => {
                        // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                        //  logged out of the console and we should close the dialog without continuing
                        if (result === undefined) {
                            this.idleTimeout.initIdleTimeout();
                            this.idleTimeoutWarningActive = false;
                        } else if (result['loggingOut'] === undefined) {
                            if (result) {
                                this.idleTimeout.initIdleTimeout();
                                this.idleTimeoutWarningActive = false;
                            } else {
                                this.idleTimeout.clearIdleTimeout();
                                this.login.logout(false);
                                this.zenDeskService.zE('webWidget', 'hide');
                            }
                        }
                    });
                }
            })
        );
    }

    ngOnDestroy() {
        if (this.growlerServiceSub) this.growlerServiceSub.unsubscribe();
        if (this.currentNetworkSub) this.currentNetworkSub.unsubscribe();
        if (this.networkServiceSub) this.networkServiceSub.unsubscribe();
        if (this.selfLinkSub) this.selfLinkSub.unsubscribe();
        if (this.lastErrorSub) this.lastErrorSub.unsubscribe();
        if (this.azureSubSub) this.azureSubSub.unsubscribe();
        if (this.networkServiceGetSub) this.networkServiceGetSub.unsubscribe();
        // clearing the idle timeout at the end of the component lifecycle
        this.idleTimeout.clearIdleTimeout();
        // clearing the interval on the login check refresher
        clearInterval(this.loginCheckRefresher);
    }

    hideWizard() {
        this.dialogRef.close();
    }

    close() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        // this.logger.info('msst');
        if (!this.menuService.locked && this.menuService.isOpen()) {
            // this.logger.info('not lockt');
            this.closeTimeout = setTimeout(() => {
                // this.logger.info('my timeout');
                this.menuService.setArea('');
            }, 2000);
        }
    }

    removeClose() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
    }

    bannerButtonClicked() {
        switch (this.bannerClass) {
            case 'unsupported':
                this.openSupport();
                break;
            case 'overdue':
                this.openBilling();
                break;
            case 'suspended':
                this.resumeNetwork();
                break;
        }
    }

    openBilling() {
        if (this.isEnterprise) {
            this.router.navigate(['/payment-profile']);
        } else {
            this.router.navigate(['/billing']);
        }
    }

    openSupport() {
        window.open('http://support.netfoundry.io');
    }

    setSuspendedBannerWarning() {
        const network: any = this.currentNetwork;
        if (this.isSelfServiceTeams) {
            const updatedDate = moment(network?.updatedAt);
            const today = moment();
            let daysLeft = 15 - Math.abs(updatedDate.diff(today, 'days'));
            if (daysLeft < 0) {
                daysLeft = 0;
            }
            this.bannerMessage = `This network has had no utilization for >7 days and has been moved to stopped state. The network will be deleted if there is no utilization in ${daysLeft} days. To continue service, please contact cust-support@netfoundry.io.`;
        } else {
            this.bannerMessage = `This network has been moved to stopped state since there was no utilization for 7 days. To continue service, please contact cust-support@netfoundry.io.`;
        }
        this.bannerClass = 'suspended';
        this.showBanner = true;
    }

    resumeNetwork() {
        let chargeWarning = '';
        if (!this.isSelfServiceTeams) {
            chargeWarning = ' Charges may apply depending on your subscription.';
        }
        const data = {
            title: 'Resume Network',
            appendId: 'AppComponentResume',
            action: 'Yes Please',
            cancelAction: 'No, get me out of here',
            subtitle: `If you would like to resume activity on this network please reach out to support`,
            isTimeoutWarning: false,
        };
        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '200px',
            width: '600px',
            autoFocus: false,
        });
        this.confirmDialog.afterClosed().subscribe((result) => {
            if (result) {
                this.showBannerSpinner = true;
                this.bannerMessage = `Network resuming. Please wait a moment...`;
                this.showSupportLink = false;
                this.customerService
                    .selfResume()
                    .then(() => {
                        this.growlService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                `Network Resume In Progress`,
                                `Your request to resume the network was successful. Please wait a moment for the update to complete.`
                            )
                        );
                    })
                    .catch((error) => {
                        this.showBannerSpinner = false;
                        this.setSuspendedBannerWarning();
                        this.growlService.show(
                            new GrowlerData(
                                'error',
                                'Error',
                                `Network Resume Failed`,
                                `Your request to resume the network failed. Please contact support is the issue persists.`
                            )
                        );
                    });
            }
        });
    }

    showGettingStarted() {
        const showSurvey = localStorage.getItem('showSurvey_' + this.tenantLabel) === 'true';
        if (showSurvey) {
            this.dialogRef = this.dialogForm.open(SurveyComponent, {
                data: {},
                height: '455px',
                width: '935px',
                autoFocus: false,
            });
            localStorage.removeItem('showSurvey_' + this.tenantLabel);
            this.menuService.setArea('');
            this.dialogRef.afterClosed().subscribe(() => {
                if (this.currentNetwork?.status === 'PROVISIONED') {
                    this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
                        data: {},
                        height: '455px',
                        width: '935px',
                        autoFocus: false,
                    });
                    this.dialogRef.afterClosed().subscribe(() => {
                        localStorage.setItem(`take-console-tour-complete_${this.tenantLabel}`, 'true');
                    });
                }
            });
        }
        this.gettingStartedWasShown = true;

        if (!this.billingInvalid) {
            this.router.navigate([URLS.GETTING_STARTED]);
        }

        localStorage.removeItem('showSurvey_' + this.tenantLabel);
        const netGroupId = _.get(this.organizationList, '[0].id');
        if (netGroupId && this.tenantLabel) {
            const createNewNetwork = localStorage.getItem('createNetwork_' + this.tenantLabel) === 'true';
            this.hasNoNetworks = !this.networks?.length || this.networks?.length === 0;
            if (createNewNetwork && this.hasNoNetworks) {
                // Console is no longer driving network creation
                //this.createNewNetwork();
                localStorage.removeItem('createNetwork_' + this.tenantLabel);
            }
        }
    }

    createNewNetwork() {
        const netGroupId = _.get(this.organizationList, '[0].id');
        const model: any = {
            selected: false,
            provider: 'OCI',
            locationCode: 'us-ashburn-1',
            name: `${this.tenantLabel}-network`,
            networkGroupId: netGroupId,
            size: this.environment.defaultSize,
        };
        const params = { body: model };
        this.networkServiceV2.createResource(params).subscribe(
            (data) => {
                this.logger.info('Create network response: ', data);
                this.apiService.setCurrentNetwork(data as NetworkV2);
                localStorage.removeItem('createNetwork_' + this.tenantLabel);
            },
            (error) => {
                this.logger.error(error?.message, error);
            }
        );
    }

    showConsoleTour() {
        this.hasNoNetworks = !this.networks?.length || this.networks?.length === 0;
        this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
            data: {},
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            const openNetworkCreation = result?.openNewNetworkDialog || this.hasNoNetworks;
            if (openNetworkCreation) {
                this.dialogRef = this.dialogForm
                    .open(V2NetworkCreationFormComponent, {
                        data: {},
                        minHeight: '100%',
                        minWidth: '100%',
                        height: '100%',
                        width: '100%',
                    })
                    .afterClosed()
                    .subscribe(() => {
                        localStorage.removeItem('nf_new_self_service');
                    });
            }
        });
    }

    showWizard() {
        this.hasNoNetworks = this.networks.length === 0;
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            data: {},
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (this.authorizationService.canListNetworks()) {
                this.networkService.get();
            }
            if (result != null && this.hasNoNetworks) {
                this.openConsoleTour();
            }
        });
    }

    openConsoleTour() {
        this.dialogRef = this.dialogForm.open(ConsoleTourComponent, { data: {}, height: '455px', width: '935px' });
    }

    showGrowlers() {
        if (this.menuService.areaId === '') {
            this.menuService.setArea('Network');
        }
        this.isGrowlersOpen = true;
    }

    closeGrowlers() {
        this.isGrowlersOpen = false;
    }

    showTheGrowler(data) {
        this.growler.title = data.title;
        this.growler.subtitle = data.subtitle;
        this.growler.level = data.level;
        this.growler.content = data.content;
        this.growler.show = true;
        setTimeout(() => {
            this.growler.show = false;
        }, 5000);
    }

    // Loads all networks
    public async loadNetworks() {
        // this.logger.info('APP LEVEL LOAD NETWORKS!');
        const proms = [];
        proms.push(this._loadV7networks());
        // this.logger.info('AWAITING ALL PROMISES');
        await Promise.all(proms).then(() => {
            // this.logger.info('ALL PROMISES FULLFILLED!');
        });
        // combine the list
        let combined = [];
        combined = combined.concat(this.networksV7);
        this.networks = combined;
        // if there are any networks
        if (combined.length > 0) {
            // check initial load
            if (this.isFirstLoad) {
                // handle some app initialization logic and check and see if we have anything stored in cookies
                this._initialNetworkLoad(combined);
                // we have already loaded the initial app context, just refresh the data and make sure nothing has changed
            } else {
                let wasCurrentNetFound = false;
                for (const network of combined) {
                    const networkModel = this.apiService.getNetworkModel(network);
                    if (this.currentNetwork.id === networkModel.id) {
                        wasCurrentNetFound = true;
                        // set the fresh copy of the model just in case the status has changed
                        this.apiService.setCurrentNetwork(networkModel);
                        break;
                    }
                }
                // if we didn't find the network model in the list then it was probably deleted, go find the first one in the list
                if (!wasCurrentNetFound) {
                    this.apiService.setCurrentNetwork(this.networks[0]);
                }
            }
            // there are no networks in the list!
        } else {
            if (this.isFirstAdd) {
                this.isFirstAdd = false;
                // set an empty v6 network as a default
                this.apiService.setCurrentNetwork(new Network({}));
            }
        }
    }

    ngAfterViewChecked() {
        (<any>window).SetupRippler();
    }

    closeWarning() {
        this.billingEnabledService.closeWarning();
    }

    async setBillingEnabled(isBillingEnabled) {
        if (isBillingEnabled) {
            this.billingEnabledService.openWarning();
        } else {
            this.billingEnabledService.closeWarning();
        }
        if (isBillingEnabled && this.authorizationService.canListComponentTypes()) {
            this.wasPricingObtained = true;
            this.billingService.initPricingData();
        }
    }

    reRouteDashboardIfZiti(page) {
        const prevVersion = this.previousNetwork ? this.apiService.getNetworkVersion(this.previousNetwork) : undefined;
        const currentVersion = this.previousNetwork
            ? this.apiService.getNetworkVersion(this.currentNetwork)
            : undefined;
        if ((!prevVersion || prevVersion < 7) && currentVersion >= 7 && ['/', URLS.HOME].includes(page)) {
            this.router.navigate([URLS.V7DASHBOARD]);
        }
    }

    private handleBannerMessageVisibility() {
        const network: any = this.currentNetwork;
        const netVersion = network?.productVersion?.charAt(0);
        const vetVersionNum = Number.parseInt(netVersion, 10);
        if (vetVersionNum === 6) {
            this.bannerMessage = `TERMINATION OF SUPPORT NOTICE: V6 is no longer supported, you are using this
                          version of our product at your own risk, click
                          <a href="https://support.netfoundry.io/hc/en-us/articles/
                          5168208798349-Notice-of-Discontinuance-and-Termination-of-Order-Form-upon-Expiration-Date"
                          target="_blank" class="banner-link">here</a> for further information.`;
            this.showBanner = true;
            this.showSupportLink = false;
            this.bannerClass = 'unsupported';
        } else if ((network as unknown as NetworkV2).status === 'SUSPENDED') {
            this.setSuspendedBannerWarning();
        } else {
            const clearBanner = this.bannerClass === 'unsupported' || this.bannerClass === 'suspended';
            if (clearBanner) {
                this.bannerMessage = '';
                this.showBanner = false;
                this.showSupportLink = false;
                this.bannerClass = '';
            }
        }
    }
    initLandingPage() {
        if (this.landingPageInit) {
            return;
        }
        this.landingPageInit = true;
        this.sleep(1000).then(() => {
            if (this.networks.length > 0) {
                this.wasWizardShown = false;
                this.reRouteDashboardIfZiti(this.page);
            } else {
                let newRoute;
                if (!_.isEmpty(this.currentNetwork?.id) && this.apiService.getNetworkVersion(this.currentNetwork) < 7) {
                    newRoute = v7tov6URLMapping[this.page];
                } else {
                    newRoute = v6tov7URLMapping[this.page];
                }
                if (newRoute != null) {
                    this.router.navigate([newRoute]);
                }
            }
            this.showLoader = false;
        });
    }

    checkBannerStatus(accountId) {
        this.bannerService.getBanners(accountId).then((result) => {
            const banner: any = _.first(result);
            if (banner?.banner?.data) {
                this.bannerMessage = banner?.banner?.data;
                this.showBanner = true;
            }
        });
    }

    getActiveSubscription(subscriptions: any) {
        if (_.isEmpty(subscriptions)) {
            return _.head(subscriptions);
        }
        let activeSub;
        try {
            for (let i = 0; i < subscriptions.length; i++) {
                const sub = subscriptions[i];
                if (_.toLower(sub?.status) !== 'cancelled') {
                    activeSub = sub;
                    break;
                }
            }
        } catch (err) {
            activeSub = _.head(subscriptions);
        }
        return activeSub;
    }

    private handleWhatsNewDisplay() {
        if (!this.isLoggedIn) {
            return;
        }
        this.whatsNewService.loadContentFile(this.isNewSelfService);
    }

    private handleQuickStartVisibility(url: string) {
        this.showQuickStart = false;
        switch (url) {
            case URLS.SIGNUP:
            case URLS.GETTING_STARTED:
            case URLS.LOGIN:
            case URLS.SAMPLE_SERVICE:
            case URLS.OLD_LOGIN:
            case URLS.SIGNUP_COMPLETE:
            case URLS.SIGNUP_RETURN:
            case URLS.PAGENOTFOUND:
            case URLS.SELFSERVICE: {
                this.showQuickStart = false;
                break;
            }
            default: {
                this.showQuickStart = (this.isSelfService || this.isNewSelfService) && !this.billingInvalid;
            }
        }
        if (this.showQuickStart && !this.apiService?.tutorialActive) {
            this.highlightQuickStartButton();
        }
    }

    private highlightQuickStartButton() {
        const showHint = localStorage.getItem('nf_show_quick_start_hint_' + this.tenantLabel) === 'true';
        if (!showHint || !this.featureService.showGettingStarted) {
            return;
        }
        const enjoy_hint_options = {
            showSkip: true,
            showNext: true,
            showPrev: true,
            btnSkipText: 'Got it',
            radius: 20,
            onEnd: () => {},
            keyCode: '',
            top: 100,
            onSkip: () => {},
        };
        const enjoyhint_instance = new EnjoyHint(enjoy_hint_options);
        const hints = [
            {
                event: 'quick-start-badge',
                selector: '.QuickStart',
                //arrowColor: 'transparent',
                margin: 0,
                shape: 'circle',
                skipButton: { className: 'quickStartSkip', text: 'Got it' },
                description:
                    '<h2>Here\'s a hint</h2><p>Use the <text style="color: #4fcbff">Quick Start</text> button at any time to return to the <text style="color: #4fcbff">Getting Started</text> page. <p>There you\'ll find links to our documentation and tutorials</p>',
                onBeforeStart: () => {},
            },
        ];
        _.delay(() => {
            enjoyhint_instance.set(hints);
            enjoyhint_instance.run();
        }, 100);
        localStorage.removeItem('nf_show_quick_start_hint_' + this.tenantLabel);
    }

    private setupSubtitle(url: string) {
        this.isHeader = true;
        this.actionTitle = '';
        this.isShaded = false;
        this.isTimeSearchAvailable = false;
        this.isNotDialLogs = true;
        this.resourceType = undefined;
        this.noPadding = false;
        switch (url) {
            // Endpoint Navigation
            case URLS.OLD_OLD_GATEWAYS:
            case URLS.OLD_GATEWAYS:
            case URLS.GATEWAYS: {
                this.subtitle = 'Gateways';
                this.actionTitle = 'Add A Gateway';
                this.breadcrumb = this.subtitle;
                this.tabs = 'endpoints';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_VIRTUALWANSITES:
            case URLS.VIRTUALWANSITES: {
                this.subtitle = 'Azure Virtual WAN Sites';
                this.actionTitle = 'Add An Azure Virtual WAN Site';
                this.breadcrumb = this.subtitle;
                this.tabs = 'endpoints';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_OLD_CLIENTS:
            case URLS.OLD_CLIENTS:
            case URLS.CLIENTS: {
                this.subtitle = 'Clients';
                this.actionTitle = 'Add A Client';
                this.breadcrumb = this.subtitle;
                this.tabs = 'endpoints';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_OLD_GROUPS:
            case URLS.OLD_GROUPS:
            case URLS.GROUPS: {
                this.subtitle = 'Endpoint Groups';
                this.actionTitle = 'Add A Group';
                this.breadcrumb = this.subtitle;
                this.tabs = 'endpoints';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            // Ziti UIs
            case URLS.OLD_ZITIAPPS:
            case URLS.ZITIAPPS: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Service Policies';
                    this.actionTitle = 'Add A Service Policy';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-app-wan';
                    this.isFilterable = true;
                    this.tabs = 'ziti-policies';
                } else {
                    this.subtitle = this.featureService?.disableNewNavigation ? 'AppWANs' : 'Service Policies';
                    this.actionTitle = `Add An ${
                        this.featureService?.disableNewNavigation ? 'AppWAN' : 'Service Policy'
                    }`;
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-app-wan';
                    this.tabs = 'zitiappwans';
                    this.isFilterable = true;
                }
                break;
            }
            case URLS.ZITIENDPOINTS: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Identities';
                    this.actionTitle = 'Add An Identity';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-endpoint';
                    this.isFilterable = true;
                    this.tabs = 'ziti-identities';
                } else {
                    this.subtitle = this.featureService?.disableNewNavigation ? 'Endpoints' : 'Identities';
                    this.actionTitle = 'Add An Endpoint';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-endpoint';
                    this.tabs = 'zitiendpoints';
                    this.isFilterable = true;
                }
                break;
            }
            case URLS.BROWZER: {
                this.subtitle = 'BrowZer Apps';
                this.actionTitle = 'Add a BrowZer App';
                this.breadcrumb = this.subtitle;
                this.resourceType = 'browzer-app';
                this.isFilterable = true;
                this.tabs = 'browzer';
                break;
            }
            case URLS.BROWZER_BOOTSTRAPPERS: {
                this.subtitle = 'BrowZer Bootstrappers';
                this.actionTitle = 'Add a BrowZer Bootstrapper';
                this.breadcrumb = this.subtitle;
                this.resourceType = 'browzer-bootstrapper';
                this.isFilterable = true;
                this.tabs = 'browzer';
                break;
            }
            case URLS.ZITIROUTERS: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Edge Routers';
                    this.actionTitle = 'Add An Edge Routers';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'edge-router';
                    this.isFilterable = true;
                    this.tabs = 'ziti-ers';
                } else {
                    this.subtitle = 'Edge Routers';
                    this.actionTitle = 'Add An Edge Routers';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'edge-router';
                    this.tabs = 'routers';
                    this.isFilterable = true;
                    break;
                }
                break;
            }
            case URLS.POSTURE_CHECKS: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Posture Checks';
                    this.actionTitle = 'Add A Posture Check';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'posture-check';
                    this.isFilterable = true;
                    this.tabs = 'ziti-posture';
                } else {
                    this.subtitle = 'Posture Checks';
                    this.actionTitle = 'Add A Posture Check';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'posture-check';
                    this.tabs = 'posture-checks';
                    this.isFilterable = true;
                }
                break;
            }
            case URLS.OLD_ZITISERVICES:
            case URLS.ZITISERVICES: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Services';
                    this.actionTitle = 'Add An Services';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-service';
                    this.isFilterable = true;
                    this.tabs = 'ziti-services';
                } else {
                    this.subtitle = 'Services';
                    this.actionTitle = 'Add An Services';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-service';
                    this.tabs = 'zitiappwans';
                    this.isFilterable = true;
                }
                break;
            }
            case URLS.ZITICONFIGS: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Configurations';
                    this.actionTitle = 'Add A Configuration';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-config';
                    this.tabs = 'ziti-services';
                    this.isFilterable = true;
                    break;
                } else {
                    this.subtitle = 'Configurations';
                    this.actionTitle = 'Add A Configuration';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-config';
                    this.tabs = 'zitiappwans';
                    this.isFilterable = true;
                    break;
                }
            }
            case URLS.ZITICONFIGTYPES: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Configuration Types';
                    this.actionTitle = 'Add A Configuration Type';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-config-type';
                    this.tabs = 'ziti-services';
                    this.isFilterable = true;
                    break;
                } else {
                    this.subtitle = 'Configuration Types';
                    this.actionTitle = 'Add A Configuration Type';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'ziti-config-type';
                    this.tabs = 'zitiappwans';
                    this.isFilterable = true;
                    break;
                }
            }
            case URLS.ZITIPOLICIES: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Edge Router Policies';
                    this.actionTitle = 'Add An Edge Router Policy';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'edge-router-policy';
                    this.isFilterable = true;
                    this.tabs = 'ziti-policies';
                } else {
                    this.subtitle = 'Edge Router Policies';
                    this.actionTitle = 'Add An Edge Router Policy';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'edge-router-policy';
                    this.tabs = 'routers';
                    this.isFilterable = true;
                }
                break;
            }
            case URLS.ZITI_SERVICE_ER_POLICIES: {
                if (!this.featureService.disableNewNavigation) {
                    this.subtitle = 'Service Edge Router Policies';
                    this.actionTitle = 'Add A Service Edge Router Policy';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'service-edge-router-policy';
                    this.isFilterable = true;
                    this.tabs = 'ziti-policies';
                } else {
                    this.subtitle = 'Service Edge Router Policies';
                    this.actionTitle = 'Add A Service Edge Router Policy';
                    this.breadcrumb = this.subtitle;
                    this.resourceType = 'service-edge-router-policy';
                    this.tabs = 'routers';
                    this.isFilterable = true;
                }
                break;
            }
            // AppWAN Navigation
            case URLS.OLD_OLD_APPWANS:
            case URLS.OLD_APPWANS:
            case URLS.APPWANS: {
                this.subtitle = 'AppWans';
                this.actionTitle = 'Add An AppWan';
                this.breadcrumb = this.subtitle;
                this.tabs = 'appwans';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_OLD_SERVICES:
            case URLS.OLD_SERVICES:
            case URLS.SERVICES: {
                this.subtitle = 'Services';
                this.actionTitle = 'Add A Service';
                this.breadcrumb = this.subtitle;
                this.tabs = 'appwans';
                this.isFilterable = true;
                break;
            }
            case URLS.BILLING: {
                this.subtitle = 'Billing Dashboard';
                this.breadcrumb = 'Billing Dashboard';
                this.tabs = 'billing';
                this.isShaded = true;
                this.isFilterable = false;
                break;
            }
            case URLS.OLD_LOGS:
            case URLS.LOGS: {
                this.subtitle = 'Organization Events';
                this.breadcrumb = 'Organization Events';
                this.tabs = 'orgLogs';
                this.actionTitle = 'Export';
                this.isFilterable = true;
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.OLD_OLD_SETTINGS:
            case URLS.OLD_SETTINGS:
            case URLS.SETTINGS: {
                this.subtitle = 'Organization Settings';
                this.breadcrumb = 'Organization Settings';
                this.tabs = 'organization';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.OLD_SUBSCRIPTIONS:
            case URLS.SUBSCRIPTIONS: {
                this.subtitle = 'Endpoint Lifecycle';
                this.breadcrumb = 'Endpoint Lifecycle';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.V6_VWAN: {
                this.subtitle = 'Virtual WAN Subscriptions';
                this.breadcrumb = 'Virtual WAN';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.CLIENT_SYNC_AAD: {
                this.subtitle = 'Azure Active Directory';
                this.breadcrumb = 'Manage AAD';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.CLIENT_SYNC_AD: {
                this.subtitle = 'Active Directory';
                this.breadcrumb = 'Manage AD';
                this.tabs = 'integrations';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.STORED_SECRETS: {
                this.subtitle = 'Stored Secrets';
                this.actionTitle = 'Add A Stored Secret';
                this.breadcrumb = 'Stored Secrets';
                this.isFilterable = true;
                this.tabs = 'stored_secrets';
                break;
            }
            case URLS.IDENTITIES_BASE:
            case URLS.OLD_OLD_USERS:
            case URLS.OLD_USERS:
            case URLS.USER_ROLES:
            case URLS.USERS: {
                this.subtitle = 'Users';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Add A User';
                this.tabs = 'users';
                this.isFilterable = true;
                break;
            }
            case URLS.USER: {
                this.subtitle = 'User Admin';
                this.breadcrumb = this.subtitle;
                this.actionTitle = '';
                this.tabs = 'user';
                this.isFilterable = true;
                break;
            }
            case URLS.OLD_DAILYSTATS:
            case URLS.OLD_RESOURCE_USAGE:
            case URLS.RESOURCE_USAGE:
            case URLS.DAILY_ENDPOINT_UTILIZATION:
            case URLS.DAILYSTATS: {
                this.subtitle = 'Reporting and Analytics';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Download CSV';
                this.tabs = 'reporting';
                this.isFilterable = false;
                this.isShaded = false;
                break;
            }
            case URLS.OLD_NETWORK_GROUP_SUMMARY:
            case URLS.NETWORK_GROUP_SUMMARY: {
                this.subtitle = 'Reporting and Analytics';
                this.breadcrumb = this.subtitle;
                this.tabs = 'reporting';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.PAYMENT_PROFILE:
            case URLS.BILLING_SUBSCRIPTIONS: {
                this.subtitle = 'Billing Management';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.ACCOUNT_DETAILS: {
                this.subtitle = 'Account Details';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_SUBSCRIPTION_OVERAGES: {
                this.subtitle = 'Billing Subscription Overages';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.BILLING_SUBSCRIPTION_USAGE: {
                this.subtitle = 'Billing Subscription Usage';
                this.breadcrumb = this.subtitle;
                this.tabs = 'billing-management';
                this.isFilterable = false;
                this.isShaded = true;
                break;
            }
            case URLS.OLD_MANAGE_INVITATIONS:
            case URLS.MANAGE_INVITATIONS: {
                this.subtitle = 'Invitations';
                this.breadcrumb = this.subtitle;
                this.actionTitle = 'Add A Invitations';
                this.tabs = 'users';
                this.isFilterable = true;
                break;
            }
            case URLS.ROLES: {
                this.subtitle = 'Roles & Permissions';
                this.actionTitle = 'Add A Role';
                this.breadcrumb = 'Roles';
                this.isFilterable = true;
                this.tabs = 'roles';
                break;
            }
            case URLS.OLD_TOKENS:
            case URLS.TOKENS: {
                this.subtitle = 'Api Accounts';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.actionTitle = 'Add Api';
                this.tabs = 'tokens';
                break;
            }
            case URLS.OLD_PROFILE:
            case URLS.PROFILE: {
                this.subtitle = 'Profile';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.isShaded = true;
                this.tabs = 'profile';
                break;
            }
            case URLS.USER_SETTINGS:
            case URLS.OLD_BRANDING:
            case URLS.BRANDING: {
                this.subtitle = 'User Settings';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.isShaded = true;
                this.tabs = 'profile';
                break;
            }
            case URLS.NOTIFICATIONS: {
                this.subtitle = 'manage Notifications';
                this.actionTitle = 'Add A Notification';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'events';
                break;
            }
            case URLS.NETWORK_GROUPS: {
                this.subtitle = 'Network Groups';
                this.actionTitle = 'Add A Network Group';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'networks';
                break;
            }
            case URLS.OLD_NETWORK:
            case URLS.NETWORKS_COMBINED:
            case URLS.NETWORK: {
                this.subtitle = 'Networks';
                this.actionTitle = 'Add A Network';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'networks';
                break;
            }
            case URLS.NETWORKS6: {
                this.subtitle = 'V6 Networks';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'networks';
                break;
            }
            case URLS.NETWORK_CONTROLLERS: {
                this.subtitle = 'Network Controllers';
                this.actionTitle = 'Add A Network Controller';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'controllers';
                break;
            }
            case URLS.ALLOCATED_IPS: {
                this.subtitle = 'Allocated Ips';
                this.actionTitle = 'Add An Allocated Ip';
                this.breadcrumb = this.subtitle;
                this.isFilterable = true;
                this.showTabs = true;
                this.tabs = 'controllers';
                break;
            }
            case URLS.PROCESS_EXECUTIONS: {
                this.subtitle = 'Network Processes';
                this.actionTitle = 'View and manage processes for the current network';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                this.showTabs = true;
                this.tabs = 'processes';
                if (this.featureService.disableNewNavigation) {
                    this.tabs = 'networks';
                } else {
                    this.tabs = 'processes';
                }
                break;
            }
            case URLS.OLD_ORGANIZATIONS:
            case URLS.ORGANIZATIONS: {
                this.subtitle = 'Organizations';
                this.actionTitle = 'Add An Organization';
                this.breadcrumb = 'Organizations';
                this.isFilterable = true;
                this.tabs = 'organizations';
                break;
            }
            case URLS.PASSWORD: {
                this.subtitle = 'Password';
                this.breadcrumb = 'User Preferences <span class="slash">/</span> ' + this.subtitle;
                this.isFilterable = false;
                this.showProfileTabs = true;
                this.showTabs = true;
                break;
            }
            case URLS.V7EVENTS:
            case URLS.OLD_EVENTS:
            case URLS.MANAGEMENT_EVENTS: {
                this.subtitle = 'Management Events';
                this.breadcrumb = 'Management Events';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.NETWORK_EVENTS: {
                this.subtitle = 'Network Events';
                this.breadcrumb = 'Network Events';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.OLD_ALARMS:
            case URLS.ALARMS: {
                this.subtitle = 'Network Alarms';
                this.breadcrumb = 'Network Alarms';
                this.isFilterable = true;
                this.actionTitle = 'Export';
                this.tabs = 'events';
                this.isTimeSearchAvailable = true;
                break;
            }
            case URLS.OLD_LOGIN:
            case URLS.LOGIN: {
                this.subtitle = 'Login';
                this.isLoggedIn = false;
                this.isFilterable = false;
                this.showTabs = false;
                this.showBanner = false;
                this.showQuickStart = false;
                break;
            }
            case URLS.PAGENOTFOUND:
            case URLS.SIGNUP:
            case URLS.SELFSERVICE: {
                this.subtitle = 'Sign Up';
                this.isLoggedIn = false;
                this.isFilterable = false;
                this.showTabs = false;
                break;
            }
            case URLS.OLD_VIRTUALWANS:
            case URLS.VIRTUALWANS: {
                this.subtitle = 'Azure Virtual WANs';
                this.breadcrumb = 'Virtual WANs';
                this.isFilterable = true;
                this.showTabs = false;
                this.tabs = 'azure';
                break;
            }
            case URLS.ERRORS: {
                this.subtitle = 'Errors';
                this.breadcrumb = this.subtitle;
                this.isFilterable = false;
                break;
            }
            case URLS.AUTH_POLICIES: {
                this.subtitle = 'Authentication Policies';
                this.breadcrumb = 'Authentication Policies';
                this.isShaded = false;
                this.isFilterable = true;
                this.actionTitle = 'Add an Authentication Policy';
                this.tabs = 'ca';
                break;
            }
            case URLS.JWT_SIGNERS: {
                this.subtitle = 'JWT Signers Policies';
                this.breadcrumb = 'JWT Signers';
                this.isShaded = false;
                this.isFilterable = true;
                this.actionTitle = 'Add a JWT Signer';
                this.tabs = 'ca';
                break;
            }
            case URLS.ZITI_CERTIFICATE_AUTHORITIES:
            case URLS.CERTIFICATE_AUTHORITIES: {
                this.subtitle = 'Certificate Authorities';
                this.breadcrumb = 'Certificate Authorities';
                this.isShaded = false;
                this.isFilterable = true;
                this.actionTitle = 'Add A CA';
                this.tabs = 'ca';
                break;
            }
            case URLS.METRICS: {
                this.subtitle = 'Metrics';
                this.breadcrumb = 'Metrics';
                this.isShaded = false;
                this.isFilterable = false;
                this.showTabs = false;
                this.tabs = '';
                break;
            }
            case URLS.SAMPLE_SERVICE:
            case URLS.GETTING_STARTED: {
                this.isShaded = true;
                this.noPadding = true;
                this.isFilterable = false;
                this.showTabs = false;
                this.tabs = '';
                this.isHeader = false;
                break;
            }
            default: {
                if (this.currentNetwork.name !== null && this.currentNetwork.name !== undefined) {
                    this.subtitle = this.currentNetwork.name;
                } else {
                    this.subtitle = 'Dashboard';
                }
                this.isHeader = false;
                this.isFilterable = false;
                this.breadcrumb = 'Dashboard';
                this.showTabs = false;
                break;
            }
        }
        if (this.subtitle !== undefined) {
            this.titleService.setTitle('NetFoundry: ' + this.subtitle);
        } else {
            this.titleService.setTitle('NetFoundry Console');
        }
        if (this.isLoggedIn) {
            this.zenDeskService.zE('webWidget', 'helpCenter:setSuggestions', { search: this.subtitle });
            this.zenDeskService.zE('webWidget', 'identify', {
                name: localStorage.getItem('profile_nick'),
                email: localStorage.getItem('profile_email'),
            });
            if (!this.isFirstPage) {
                this.zenDeskService.zE('webWidget', 'hide');
                this.isFirstPage = true;
            }
        } else {
            this.zenDeskService.zE('webWidget', 'hide');
        }
        if (this.isLoggedIn) {
            // this.zenDeskService.setHelpCenterSuggestions({ search: this.subtitle });
            // this.zenDeskService.zE("webWidget", "identify", {
            //   name: localStorage.getItem("profile_nick"),
            //   email: localStorage.getItem("profile_email")
            // });
            if (!this.isFirstPage) {
                // this.zenDeskService.zE("webWidget", "hide");
                this.isFirstPage = true;
            }
        } else {
            // this.zenDeskService.zE("webWidget", "hide");
        }
    }

    private setFavicon(faviconUrl) {
        if (document != null && document.getElementById('appFavicon') != null) {
            document.getElementById('appFavicon').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple57').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple60').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple72').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple76').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple114').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple120').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple144').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple152').setAttribute('href', faviconUrl);
            document.getElementById('appFaviconApple180').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon192').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon32').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon96').setAttribute('href', faviconUrl);
            document.getElementById('appFavicon16').setAttribute('href', faviconUrl);
        }
    }

    /**
     * Set the OrgID for the app
     */
    private async setOrganization(orgId) {
        // making sure the user is logged in before attempting to set the org
        if (this.isLoggedIn && orgId !== this.currentOrg.id) {
            const org = this.orgLookup[orgId];
            if (orgId != null && org != null) {
                localStorage.setItem('organizationId', orgId);
                this.logger.info('Setting ORG', org);
                this.currentOrg = new NetworkGroup(org);
                this.apiService.setCurrentOrg(this.currentOrg);
                this.featureService.checkFeatures(orgId);
                if (this.authorizationService.canListAzureSubscriptions()) {
                    this.getAzureSubscription(this.currentOrg);
                }
            } else {
                this.logger.info('OrgID is empty!');
            }
        } else {
            return;
        }
    }

    private async subscriptionChanged(subscription) {
        if (!this.isSelfService) {
            return;
        }
        if (this.featureService.showGettingStarted && !this.gettingStartedWasShown) {
            this.showGettingStarted();
        }
        this.isSelfServiceTeams = this.featureService.isSelfServiceTeamsTier(subscription);
        const isNewInvitedUser = localStorage.getItem('newInvitedUser_' + this.tenantLabel) === 'true';
        if (isNewInvitedUser && this.isSelfService) {
            this.customerService.setNewSelfServiceUserFlags(this.tenantLabel);
        }
        if (!_.isEmpty(this.currentPaymentProfile)) {
            this.checkPaymentMethodStatus(this.currentPaymentProfile);
        }
        this.handleQuickStartVisibility(this.page);
        this.handleBannerMessageVisibility();
    }

    private async paymentProfileChanged(paymentProfile) {
        if (this.isSelfService && !_.isEmpty(this.currentSubscription)) {
            this.checkPaymentMethodStatus(paymentProfile);
        }
    }

    private checkPaymentMethodStatus(paymentProfile) {
        if (
            !this.authorizationService.isSupportUser() &&
            !this.isEnterprise &&
            !this.isSelfServiceTeams &&
            paymentProfile?.paymentMethod?.status === 'INVALID'
        ) {
            this.apiService.setBillingStatusInvalid(true);
            this.billingInvalid = true;
            this.router.navigate([URLS.BILLING]);
        }
    }

    private getAzureSubscription(currentOrg: NetworkGroup) {
        this.apiService
            .getLinkedResource(currentOrg, 'azureSubscription')
            .toPromise()
            .then((result) => {
                this.wasAzureSubscriptionInitialized = true;
                this.hasAzureSubscription = false;
                if (
                    result['_embedded'] &&
                    result['_embedded']['azureSubscriptions'] &&
                    result['_embedded']['azureSubscriptions'].length > 0
                ) {
                    const azureSubscriptions = result['_embedded']['azureSubscriptions'];
                    this.hasAzureSubscription = true;
                    this.logger.info('Set Azure Subscription', azureSubscriptions[0]);
                    this.apiService.setCurrentAzureSubscription(new AzureSubscription(azureSubscriptions[0]));
                } else {
                    this.apiService.setCurrentAzureSubscription(new AzureSubscription({}));
                }
            });
    }

    private initNetworkLimits() {
        if (_.isEmpty(this.currentNetwork?.id)) {
            return;
        }
        this.networkServiceV2
            .getNetworkLimits(this.currentNetwork.id)
            .toPromise()
            .then((networkLimits: any) => {
                this.networkLimits = networkLimits?._embedded?.networkLimitList || [];
                const currentLimit = _.first(this.networkLimits);
                this.networkServiceV2.setCurrentNetworkLimit(currentLimit);
            });
    }

    private _initialNetworkLoad(combined) {
        this.isFirstLoad = false;
        let cookieSelfLink = null;
        let cookieNetwork = null;
        let network;
        // check if we have a network saved in cookies
        if (this.cookieService.get('currentNetwork')) {
            cookieSelfLink = this.cookieService.get('currentNetwork');
            // this.logger.info('COOKIE LINK', cookieSelfLink);
            cookieNetwork = combined.find((netwrk) => netwrk._links.self.href === cookieSelfLink);
        }
        // if we have a selfLink saved in cookies and we found a matching network, load it up
        if (cookieSelfLink && cookieNetwork) {
            this.logger.info('Load network from cookie');
            network = this.apiService.getNetworkModel(cookieNetwork);
            // else we didn't find any saved network in the cookies
        } else {
            this.logger.info('No cookie network found, load first network that is not in error');
            const ignoredNetworkStates = [500, 'ERROR', 'DELETING', 'DELETED'];
            let networkToSelect = combined.find((nw) => !ignoredNetworkStates.includes(nw.status));
            if (!networkToSelect) {
                networkToSelect = combined[0];
            }
            network = this.apiService.getNetworkModel(networkToSelect);
        }
        this.apiService.setCurrentNetwork(network);
        // land on the correct page upon an initial app load
        // if(this.apiService.getNetworkVersion(network) < 7) {
        //   this.router.navigate(['/main']);
        // } else {
        //   // @TODO - Change this to be the constant in case we change the route names
        //   this.router.navigate(['/zdashboard']);
        // }
    }

    private async _loadV7networks() {
        return await this.networkServiceV2.getNetworksPage({ pageParams: { size: 2000, page: 0 } }).then(
            (networks: NetworkV2[]) => {
                const v2Networks = [];
                for (const v2Network of networks) {
                    // only show v2 networks that are in a living state
                    if (v2Network.status !== 'DELETING' && v2Network.status !== 'DELETED') {
                        v2Networks.push(v2Network as NetworkV2);
                    }
                }
                this.networksV7 = v2Networks;
            }, // @TODO - better handling - 404's are ERROR but OK if no one has V2 networks yet
            (error) => {
                this.logger.error('Error returning V2 networks for app-component', error);
                this.networksV7 = [];
            }
        );
    }

    setTempOrganization() {
        const tmpOrganizationId = _.get(this.organizationList, '[0].id');
        this.setOrganization(tmpOrganizationId);
    }
}
